import { Signal } from "@preact/signals-core";
import { useSignal } from "@preact/signals";

export default function MiniSearch({ q }: { q: string }) {
  const query = useSignal(q)

  return (
    <input
      type="text"
      name="search"
      autoComplete={"search"}
      class="search form-control m-0 min-w-[100px] max-w-[200px]"
      value={query.value}
      onChange={(e) => location.replace(`/search?q=${e.currentTarget.value}`)}
      placeholder="Что вы ищете?"
    />
  );
}
